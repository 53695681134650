import {useEffect} from 'react';
import {ApolloProvider} from '@apollo/client/react';
import {Helmet} from 'react-helmet';
import store from 'store/dist/store.modern';
import {ToastContainer} from 'react-toastify';
import graphQLClient from './src/helpers/graphql/client';
import {userSettingsVar} from './src/helpers/graphql/variables';
import './src/assets/styles/core.css';

const UserSettingsProvider = ({children}) => {
    useEffect(() => {
        const getInitialUserSettings = () => {
            if (!store.get('userSettings')) {
                store.set('userSettings', {});
            }

            return store.get('userSettings');
        };

        userSettingsVar(getInitialUserSettings());
    }, []);

    return children;
};

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({element}) => (
    <UserSettingsProvider>
        <ApolloProvider client={graphQLClient}>
            <Helmet>
                <meta name="theme-color" content="#ffffff"/>
                <link rel="icon" href="/images/favicons/favicon.svg"/>
                <link rel="apple-touch-icon" href="/images/favicons/apple-touch-icon.png"/>
                <link rel="manifest" href="/images/favicons/manifest.json"/>
                <link rel="preconnect" href="https://fonts.gstatic.com"/>
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap" rel="stylesheet"/>
            </Helmet>
            {element}
            <ToastContainer
                position="top-right"
                autoClose={7000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
            />
        </ApolloProvider>
    </UserSettingsProvider>
);
