import fetch from 'isomorphic-fetch';
import axios from 'axios';
import {accountVar} from './variables';

export const customFetch = (uri, options) => {
    const refreshOptions = options;
    const {accessToken, accessTokenExpiration} = accountVar();
    const currentTime = Math.floor(Date.now() / 1000);

    let refreshingPromise = null;

    if (accessToken && accessTokenExpiration > (currentTime + 60)) {
        refreshOptions.headers.Authorization = `Bearer ${accessToken}` || '';
    }

    const initialRequest = fetch(uri, refreshOptions);

    return initialRequest.then(response => response.json()).then(json => {
        if (json && json.errors && json.errors[0] && json.errors[0].extensions.code === 'UNAUTHENTICATED') {
            if (!refreshingPromise) {
                refreshingPromise = fetch(`${process.env.GATSBY_API_BASE_URL}/auth/refreshToken`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then(result => result.json()).then(result => {
                    accountVar(result.data);
                    if (typeof window !== 'undefined') {
                        localStorage.setItem('accountState', JSON.stringify({
                            uid: result.data.userId,
                            appScopes: result.data.appScopes,
                            teamApps: result.data.teamApps,
                        }));
                    }

                    return result.data.accessToken;
                }).catch(() => {
                    if (typeof window !== 'undefined') {
                        localStorage.removeItem('accountState');
                    }

                    window.location.href = '/account/login';
                });
            }

            return refreshingPromise.then(newAccessToken => {
                const newOptions = options;

                refreshingPromise = null;

                newOptions.headers.authorization = `Bearer ${newAccessToken}`;

                return fetch(uri, newOptions);
            });
        }

        const result = {};

        result.ok = true;
        result.text = () => new Promise(resolve => {
            resolve(JSON.stringify(json));
        });

        return result;
    }).catch(() => {
        window.location.href = '/500';
    });
};

export const customUploadFetch = (uri, options) => {
    const refreshOptions = options;
    const {accessToken, accessTokenExpiration} = accountVar();
    const currentTime = Math.floor(Date.now() / 1000);

    if (accessToken && accessTokenExpiration > (currentTime + 60)) {
        refreshOptions.headers.Authorization = `Bearer ${accessToken}` || '';
    }

    return new Promise(resolve => {
        axios({
            url: uri,
            method: 'post',
            headers: refreshOptions.headers,
            data: refreshOptions.body,
            onUploadProgress: ({total, loaded}) => {
                const progress = Math.trunc((loaded / total) * 100);

                if (options.onProgress && typeof options.onProgress === 'function') {
                    options.onProgress(progress);
                }
            },
        })
            .then(res => {
                const result = {};

                result.ok = true;
                result.text = () => new Promise(resolver => {
                    resolver(JSON.stringify(res.data));
                });

                resolve(result);
            })
            .catch(() => {
                if (typeof window !== 'undefined') {
                    localStorage.removeItem('accountState');
                }

                window.location.href = '/account/login';
            });
    });
};
