import {makeVar} from '@apollo/client';
import {Applications} from '../../graphql-types';

interface IAccountVar {
    appScopes?: Applications,
    userId: string,
}

export const accountVar = makeVar<IAccountVar>({});
export const userSettingsVar = makeVar({});
