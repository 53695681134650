import {ApolloClient} from '@apollo/client';
import {ApolloLink} from 'apollo-link';
import {BatchHttpLink} from 'apollo-link-batch-http';
import {createUploadLink} from 'apollo-upload-client';
import {customFetch, customUploadFetch} from './fetch';
import cache from './cache';

const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        const omitTypename = (key, value) => (key === '__typename' ? undefined : value);

        // eslint-disable-next-line no-param-reassign
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }

    return forward(operation).map(data => data);
});

const options = {
    fetch: customFetch,
    uri: `${process.env.GATSBY_API_BASE_URL}/graphql`,
};

const uploadOptions = {
    fetch: customUploadFetch,
    uri: `${process.env.GATSBY_API_BASE_URL}/graphql`,
    headers: {'Apollo-Require-Preflight': 'true'},
};

const httpLink = ApolloLink.split(
    operation => operation.getContext().hasUpload,
    createUploadLink(uploadOptions),
    ApolloLink.from([cleanTypeName, new BatchHttpLink(options)])
);

const client = new ApolloClient({
    link: httpLink,
    cache,
    resolvers: {},
    defaultOptions: {
        notifyOnNetworkStatusChange: true,
    },
    connectToDevTools: true,
});

export default client;
