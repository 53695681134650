import {InMemoryCache} from '@apollo/client';
import mergeObjects from 'lodash/merge';
import {accountVar} from './variables';
import generatedIntrospection from '../../fragmentMatcher.json';

export default new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: {
        Query: {
            fields: {
                account: {
                    read() {
                        return accountVar();
                    },
                },
                me: {
                    merge: true,
                },
                landingPage: {
                    read(_, {args, toReference}) {
                        return toReference({
                            __typename: 'LandingPage',
                            id: args?.id,
                        });
                    },
                },
            },
        },
        GiftFinder: {
            fields: {
                name: {
                    merge: true,
                },
            },
        },
        Product: {
            fields: {
                categories: {
                    passionPoints: {
                        merge(existing, incoming) {
                            return mergeObjects({}, existing, incoming);
                        },
                    },
                    theme: {
                        merge(existing, incoming) {
                            return mergeObjects({}, existing, incoming);
                        },
                    },
                },
                media: {
                    gallery: {
                        merge: false,
                    },
                    ar: {
                        merge: false,
                    },
                    video: {
                        merge: false,
                    },
                },
                basicInfo: {
                    merge(existing, incoming) {
                        return mergeObjects({}, existing, incoming);
                    },
                },
            },
        },
        PassionPoint: {
            fields: {
                name: {
                    merge: false,
                },
                category: {
                    merge: false,
                },
            },
        },
    },
});
